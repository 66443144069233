<template>

  <v-overlay ref="overlay" :layer="layer" preventClose>
    
    <transition name="fadeY">

      <section v-if="request" class="flex flex-col bg-gpgray-lightest rounded-2xl select-none" :style="`max-width: ${ maxWidth }px;`">
        <article class="flex flex-col p-8 pt-0 gap-2">
          <div v-show="request?.header?.icon" :class="`flex items-center bg-gpgray-lightest rounded-full p-3 self-start -translate-y-4 translate-x-4 scale-125`">
            <v-icon :path="request?.header?.icon" size="8" :color="request?.header?.color ?? 'blue-700'"/>
          </div>
          <div class="flex flex-col">
            <h1 class="mb-2">{{ request.title }}</h1>
            <p class="leading-5">{{ request.description }}</p>
          </div>

          <div :id="modalID" class="flex flex-col">
          </div>

          <div class="flex gap-4 mt-2 self-end">
            <v-button v-for="action in request.actions" :key="action.title"
            :disabled="!(action.validator())"
            :red="action.color == 'red'"
            :blue="action.color == 'blue'"
            :gray="action.color == 'gray'"
            :orange="action.color == 'orange'"
            @click="() => {
              action.call();
              CloseModal();
            }">{{ action.title }}</v-button>
          </div>
        </article>
      </section>

    </transition>
    
  </v-overlay>

</template>

<script lang="ts">

import { ref, onMounted, watch, Ref } from "vue"
import { requests, request } from "@/data/modals"
import { uuidv4 } from '@/api/api';

export default {
  props:{
    layer: {
      type: Number,
      default: 1,
    },
    maxWidth: {
      type: Number,
      default: 500,
    }
  },
  setup() {
    const modalID = ref('');

    const overlay = ref();
    let watchRequestCount = requests.value.length;
    watch(requests, () => {
      if(requests.value.length != watchRequestCount){
        NextModal();
      }
    }, { deep: true })

    onMounted(Initialization);

    function NextModal(){
      watchRequestCount = requests.value.length;
      if(watchRequestCount > 0){
        request.value = requests.value[0];
        modalID.value = 'a' + uuidv4().split('-').join('');
        setTimeout(() => {
          if(request.value?.events?.onOpen) request.value?.events?.onOpen(modalID.value);
        })
        overlay.value.open();
      }
    }

    function Initialization(){
      if(request.value){
        modalID.value = 'a' + uuidv4().split('-').join('');
        setTimeout(() => {
          if(request.value?.events?.onOpen) request.value?.events?.onOpen(modalID.value);
        })
        overlay.value.open();
      }
    }

    function CloseModal(){
      if(requests.value[0]?.events?.onClose) requests.value[0]?.events?.onClose();
      overlay.value.close();
      request.value = undefined; //delete request
      requests.value.splice(0, 1); //Remove last

      setTimeout(() => {
        NextModal();
      })
    }
    
    return {
      overlay,
      request,
      modalID,

      CloseModal,
    }
  },
}
</script>