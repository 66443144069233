<template>
    <section class="flex flex-col p-8 border-4 border-dashed transition-all cursor-pointer"
    @drag="drag"
    @dragstart="dragstart"
    @dragend="dragend"
    @dragenter="dragenter"
    @dragleave="dragleave"
    @dragover="dragover"
    @drop="drop"
    @click.self="clickUploader"

    :class="{
        'border-gray-300' : !isHoverDrag,
        'border-gpblue' : isHoverDrag,
    }"
    >
        <div class="flex flex-col gap-4  pointer-events-none">
            <v-icon path="fi-rr-upload" :color="isHoverDrag ? 'gpblue' : 'gray-400'" class="transform transition-all" :class="{ '-translate-y-1' : isHoverDrag}" size="6"></v-icon>
            <p class="font-bold text-center">{{ t("Select a file") }}</p>
        </div>
        <input type="file" ref="uploaderInput" @change="changeUploader" v-show="false" :multiple="multiple">
        <div class="flex flex-col cursor-auto gap-2 mt-4" v-show="filesUpload.length > 0 && !noupload">
            <div v-for="(fileElement, index) in filesUpload" :key="fileElement.name" class="flex gap-4 justify-between">
                <!-- <div class="p-6 bg-red-300 rounded"></div> -->
                <div class="flex flex-col justify-center gap-1 flex-1">
                    <div class="flex gap-1">
                        <div class="flex flex-col">
                            <p>{{ fileElement.file.name }}</p>
                        </div>
                    </div>
                    <div class="flex rounded-full overflow-hidden">
                        <progress min="0" max="0" :value="fileElement.infoUpload.percentage ? fileElement.infoUpload.percentage : 0" class="flex-1 h-1"></progress>
                    </div>
                </div>
                <div class="flex">
                    <v-icon v-show="!fileElement.infoUpload.finish" path="fi-rr-cross" size="3" class="cursor-pointer" @click="removeFile(index)"></v-icon>
                    <v-icon v-show="fileElement.infoUpload.finish" path="fi-rr-check" size="3" color="green-500"></v-icon>
                </div>
            </div>
        </div>
        <div class="flex flex-col justify-end transition-all overflow-hidden duration-700" :class="{ 'opacity-0 h-2' : filesUpload.length <= 0, 'h-14': filesUpload.length > 0 }" v-show="!autoupload && !noupload">
            <v-button blue @click="runUploadFiles()" :disabled="filesUpload.length <= 0">
                <p class="px-4">{{ t("upload files") }}</p>
            </v-button>
        </div>
    </section>
</template>

<script>
import { ref } from 'vue'
import { UploadFile } from "@/api/upload";

export default {
    components: {},
    emits: ["uploaded", "files"],
    props: {
        noupload: {
            type: Boolean,
            default: false,
        },
        autoupload: {
            type: Boolean,
            default: false,
        },
        multiple : {
            type: Boolean,
            default: false,
        }
    },
    setup(props, context) {
        const isHoverDrag = ref(false);
        const uploaderInput = ref();

        const filesUpload = ref([]);

        async function runUpload(){
            for(let i = 0; i < filesUpload.value.length; i++){
                console.log(filesUpload.value);
                UploadFile(filesUpload.value[i].file, {
                    progress: (infoUpload) => {
                        console.debug("UPLOAD CALLBACK", infoUpload);
                        filesUpload.value[i].infoUpload.id = infoUpload.id;
                        filesUpload.value[i].infoUpload.finish = infoUpload.finish;
                        filesUpload.value[i].infoUpload.percentage = infoUpload.percentage;
                    }
                }).then((finalInfo) => {
                    console.log("UPLOAD END", finalInfo);
                    context.emit("uploaded", finalInfo);
                })
            }
        }

        function removeFile(index){
            filesUpload.value.splice(index, 1);
        }

        function drag(event){
            // console.log("drag", event);
        }
    
        function dragstart(event){
            // console.log("dragstart", event);
            event.preventDefault();
        }
        function dragend(event){
            isHoverDrag.value = false;
            // console.log("dragend", event);
            event.preventDefault();
        }
        function dragenter(event){
            // console.log("dragenter", event);
            event.preventDefault();
        }
        function dragleave(event){
            isHoverDrag.value = false;
            event.preventDefault();
        }
        function dragover(event){
            isHoverDrag.value = true;
            event.preventDefault();
        }
        function drop(event){
            isHoverDrag.value = false;
            event.preventDefault();

            if (event.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                for (let i = 0; i < event.dataTransfer.items.length; i++) {
                    // If dropped items aren't files, reject them
                    if (event.dataTransfer.items[i].kind === 'file') {
                        let file = event.dataTransfer.items[i].getAsFile();
                        
                        // console.log('... file[' + i + '].name = ' + file.name);
                        filesUpload.value.push({
                            file,
                            infoUpload: {},
                        });
                    }
                }
            }

            if(!props.multiple){
                filesUpload.value = [filesUpload.value[filesUpload.value.length - 1]]; //Get only the last
            }

            context.emit("files", filesUpload.value);

            if(props.autoupload && !props.noupload){
                runUpload();
            }
            // console.log("drop", event.dataTransfer.items);
        }

        function clickUploader(event){
            uploaderInput.value.click();
        }

        const openFileCallbacks = [];

        function OpenFile() {
            openFileCallbacks.length = 0;
            uploaderInput.value.click();
            return new Promise((res, rej) => {
                openFileCallbacks.push(res);
            }) 
        }

        function changeUploader(event){
            // console.log(event);
            event.preventDefault();

            // console.log(event.target.files);
            for(let i = 0; i < event.target.files.length; i++){
                filesUpload.value.push({
                    file: event.target.files[i],
                    infoUpload: {},
                });
                // event.target.files[i].infoUpload = {};
                // filesUpload.value.push(event.target.files[i]);
            }

            if(!props.multiple){
                filesUpload.value = [filesUpload.value[filesUpload.value.length - 1]]; //Get only the last
            }
            context.emit("files", filesUpload.value);

            if(props.autoupload){
                runUpload();
            }

            event.target.value = "";

            for (let i = 0; i < openFileCallbacks.length; i++) {
                openFileCallbacks[i](filesUpload.value);
            }
            openFileCallbacks.length = 0;
        }

        function runUploadFiles(){
            runUpload();
        }
        
        return {
            isHoverDrag,
            uploaderInput,
            filesUpload,

            removeFile,

            drag,
            dragstart,
            dragend,
            dragenter,
            dragleave,
            dragover,
            drop,

            clickUploader,
            changeUploader,
            runUploadFiles,

            OpenFile,
        };
    },
}
</script>

<style scoped>



</style>