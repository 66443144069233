<template>
  <label class="flex flex-col gap-1" :class="{
    'rounded-xl group bg-gradient-to-tr from-gray-50 to-white border border-gp-gray p-2' : textarea
  }">
    <p v-show="label" class="font-bold text-gray-500" style="font-size: 0.6rem;">
      {{ label }}
      <span class="text-purple-600 font-bold transition-all" :class="{
        'opacity-0': ValidModel(),
      }">*</span>
    </p>
    <div v-show="textarea" class="flex gap-1">
      <div v-for="lang in langs" :key="lang" @click="selectedLang = lang; $emit('changeLang', selectedLang)" class="stack flex items-center justify-center gap-3 px-4 py-1 bg-white cursor-pointer transition-all rounded-xl basis-16 border" :class="{
        'hover:bg-gray-200' : selectedLang != lang,
        'bg-neutral-900 text-white' : selectedLang == lang,
      }">
        <p>{{ lang }}</p>
        <span class="text-purple-600 font-bold transition-all translate-x-4 -translate-y-1" :class="{ 'opacity-0': model[lang] }">*</span>
      </div>
    </div>
    <textarea v-show="textarea" ref="textareaRef" v-model="model[selectedLang]" @input="() => {
      textareaRef.style.height = 'auto'; textareaRef.style.height = textareaRef.scrollHeight + 'px'; $emit('change', model)
    }" :placeholder="t('insert_text')" class="bg-transparent outline-none" :class="{
      'flex-1': autosize
    }"></textarea>
    <div v-show="!textarea" class="flex flex-col rounded-xl overflow-hidden border divide-y transition-all" :class="{
      'border-purple-600' : !ValidModel()
    }">
        <div v-for="lang in langs" class="flex">
            <div class="flex items-center justify-center bg-gradient-to-tr from-gray-200 to-gray-100 w-12 p-2 font-bold text-gray-700 uppercase">{{ lang }}</div>
            <label class="flex-1 grid grid-rows-1 grid-cols-1 p-2 bg-gradient-to-tr from-gray-50 to-white">
                <input v-model="model[lang]" class="col-start-1 row-start-1 flex-1 outline-none bg-transparent" placeholder="" step="1" type="text" style="min-width: 0px;">
            </label>
        </div>
    </div>
  </label>
</template>

<script setup lang="ts">

import { ref } from "vue"

const emit = defineEmits(['change', 'changeLang']);

const props = defineProps({
  model: { type: Object, default: {}},
  label: { type: String, default : '' },
  langs: { type: Array, default : ['en', 'fr']},
  required: { type: Boolean, default: false },
  textarea: { type: Boolean, default : false },
  autosize: { type : Boolean, default : false },
})

const selectedLang = ref(props.langs[0]);
const textareaRef = ref();

function ValidModel(){
  if(!props.required) return true;
  for(let i = 0; i < props.langs.length; i++){
    if(!props.model[props.langs[i]]) return false;
  }
  return true;
}

</script>