<template>
    <label class="labelParent p-2 grid grid-rows-1 grid-cols-1 rounded-xl group bg-gradient-to-tr from-gray-50 to-white border border-gp-gray cursor-text filter transition overflow-hidden" :class="{
        'border-red-400' : error,
        'focus-within:border-blue-400 focus-within:shadow' : !error,
        'saturate-0 brightness-75 pointer-events-none': disabled,
    }">
        <div class="flex self-stretch col-start-1 row-start-1 gap-2">
            <v-icon v-show="(icon || type == 'password') && leftIcon" class="cursor-pointer" :path="type == 'password' ? (displayPassword ? 'fi-rr-eye-crossed' : 'fi-rr-eye') : icon" color="gray-600" @click="displayPassword = !displayPassword"></v-icon>

            <input ref="input" class="col-start-1 row-start-1 flex-1 outline-none bg-transparent" style="min-width: 0px;" :placeholder="placeholder" :step="step" :type="(type != 'password') ? type : (displayPassword ? 'text' : 'password')" :minlength="min" :maxlength="max" :value="modelValue" @input="valueChange($event)" @focus="$emit('focus')" @blur="$emit('blur')" :class="`${modelValue !== undefined && label && modelValue !== null && modelValue !== '' ? 'inputElement' : ''}`">

            <v-icon v-show="(icon || type == 'password') && !leftIcon" class="cursor-pointer" :path="type == 'password' ? (displayPassword ? 'fi-rr-eye-crossed' : 'fi-rr-eye') : icon" color="gray-600" @click="displayPassword = !displayPassword"></v-icon>
            <div v-show="suffix" class="flex items-center p-2 bg-gpgray-lightest dark:bg-gray-700" style="margin: -0.5rem; margin-left:0.5rem">{{suffix}}</div>
        </div>
        <div v-show="label" class="flex self-stretch items-strech col-start-1 row-start-1">
            <span class="col-start-1 row-start-1 transition spanElement font-bold select-none" :class="{
                'spanMove': (modelValue !== undefined && modelValue !== null && modelValue !== '') || label == null || type == 'date',
                'text-gray-500': !error,
                'text-red-400' : error,
            }">{{label}}</span>
        </div>
    </label>
</template>

<script>
import gpIcon from "./v-icon.vue"
import { ref } from "vue";

export default {
    components: {
        gpIcon
    },
    props: {
        label: {type: String, default: null},
        icon: {type: String, default: ''},
        leftIcon: { type: Boolean, default: false },
        type: {type: String, default: 'text'},
        placeholder: {type: String, default: ''},
        min: {type: [String, Number], require: false },
        max: {type: [String, Number], require: false },
        step: {type: [String, Number], default: 1 },
        suffix: {type: String, default: ""},
        error: {type: [String, Boolean], default : "" },
        modelValue: {type: [String, Number], default : "" },
        disabled: { type: Boolean, default: false },
        default: "",
    },
    emits: ['update:modelValue', 'change', 'focus', 'blur'],
    setup(props, { emit }) {
        const contentText = ref('');
        const input = ref();
        const displayPassword = ref(false);

        function valueChange(e){
            const toReturn = props.type == "number" ? parseFloat('0' + e.target.value) : e.target.value;
            emit('update:modelValue', toReturn);
            emit('change', toReturn);
        }

        function focus(){
            input.value.focus();
        }

        function select(){
            input.value.select();
        }

        return {
            contentText,
            valueChange,
            input,
            focus,
            select,
            displayPassword,
        }

    },
}
</script>

<style>
    .spanElement{
        transform-origin: left center;
    }
    .spanMove{
        transform: scale(0.6) translateY(calc(-100% + 0.5rem));
    }
    .inputElement{
        transform: translateY(20%);
    }
</style>