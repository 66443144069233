<template>
    <div class="flex flex-col self-center items-center justify-center flex-1 overflow-hidden h-full" style="width: 100%;">
        <canvas ref="canvasChartEx" class=""></canvas>
    </div>
</template>

<script>
import {ref, onMounted, onUnmounted, onRenderTriggered, watch, shallowRef} from "vue"

import { Chart, registerables } from "chart.js"
// import zoomPlugin from 'chartjs-plugin-zoom';
// import chartDataLabels from 'chartjs-plugin-datalabels';

if(registerables) Chart.register(...registerables);
// Chart.register(zoomPlugin);
// Chart.register(chartDataLabels);

export default {
    props: {
        type: {
            type: String,
            default: 'line'
        },
        data: {
            type: Object,
            default: {},
        },
        scalesOptions: {
            type: Object,
            default: {},
        },
        legendOptions: {
            type: Object,
            default: {},
        },
        pluginsOptions: {
            type: Object,
            default: undefined,
        },
        customOptions : {
            type: Object,
            default: {},
        },
        hideDatalabels: {
            type: Boolean,
            default: false,
        }
    },
    setup(props) {
        const canvasChartEx = ref();

        let myChart = shallowRef();

        const defaultLegendOptions = {
            position: 'left',
            align: 'start',
            labels: {
                padding: 7,
                textAlign: "left",
                font: {
                    family: "'Roboto'",
                    size: 12,
                },
                boxWidth: 25,
            },
            maxWidth: 20000, //No limit
            fullSize: true,
        };

        watch(() => props.data, reloadCanvas);
        watch(() => props.type, reloadCanvas);

        watch(() => props.legendOptions, () => {
            if(myChart.value){
                myChart.value.options.plugins.legend =  { ...defaultLegendOptions, ...props.legendOptions };
            }
        })

        function GetConfig() {
            const config = {
                type: props.type,
                data: props.data,
                options: {
                    events: ['mousemove', 'mouseout', 'touchmove'],
                    animation: {
                        duration: 0 // general animation time
                    },
                    scales: props.scalesOptions,
                    plugins: {
                        legend: { ...defaultLegendOptions, ...props.legendOptions },
                        datalabels: props.hideDatalabels ? false : {
                            anchor: (context) => {
                                // console.log(context, context.dataset.data[context.dataIndex]);
                                if(context.dataset.data[context.dataIndex] < 0) return "start";
                                return "end";
                            },
                            align: (context) => {
                                // console.log(context, context.dataset.data[context.dataIndex]);
                                if (context.dataset.data[context.dataIndex] < 0) return "start";
                                return "end";
                            },
                            offset: 0,
                            color: 'rgb(20,20,20)',
                            textAlign: 'center',
                            textStrokeColor: 'white',
                            textStrokeWidth: 3,
                            clamp: true,
                            // clip: true,
                            display: (context) => {
                                return true;
                                return context.dataIndex != context?.chart?.data?.datasets.length;
                            },
                            padding: 5,
                            font: {
                                weight: 600,
                                size: 12,
                            },
                            formatter(value, context) {
                                const multiplier = Math.pow(10, 1);
                                return Math.round(value * multiplier) / multiplier;
                            }
                        }
                    },
                    responsive: true,
                    // resizeDelay: 0,
                    aspectRatio: 1,
                    devicePixelRatio: 2,
                    maintainAspectRatio: false,
                    ...props.customOptions,
                }
            };
            if (props.pluginsOptions) config.options.plugins = props.pluginsOptions;
            return config;
        }

        onMounted(() => {
            const ctx = canvasChartEx.value.getContext("2d");
            const config = GetConfig();
            myChart.value = new Chart(ctx, config);
            window.addEventListener('resize', resizeCanvas);
            resizeCanvas();
        });

        onUnmounted(() => {
            window.removeEventListener('resize', resizeCanvas);
        })

        let timeoutResize = null;

        function resizeCanvas(){
            if(myChart.value) {
                const containTransform = ContainTransform(myChart.value.canvas);
                myChart.value.canvas.style.display = "none";
                // const bouding = myChart.canvas.parentNode.getBoundingClientRect();
                
                
                // if(timeoutResize) clearTimeout(timeoutResize);
                // timeoutResize = setTimeout(() => {
                //     myChart.resize();
                // })
                // console.log(bouding);
                // myChart.resize();
                if(containTransform) {
                    const chainTransform = ChainTransform(myChart.value.canvas);
                    // console.log(chainTransform);
                    myChart.value.canvas.parentNode.style.width = `${chainTransform.width}px !important`;
                    myChart.value.canvas.parentNode.style.height = `${chainTransform.height}px !important`;
                    // myChart.value.canvas.width = chainTransform.width;
                    // myChart.value.canvas.height = chainTransform.height;
                    // myChart.value.update();
                    myChart.value.canvas.style.minWidth = `${chainTransform.width}px !important`;
                    myChart.value.canvas.style.minHeight = `${chainTransform.height}px !important`;
                }
                else myChart.value.resize();

                myChart.value.canvas.style.display = "block";
            }
        }

        function parseTransform(transform){
            //add sanity check
            return transform.split(/\(|,|\)/).slice(1,-1).map( function(v){
                return parseFloat(v);
            });
        }

        function ChainTransform(entry, maxDepth = 5){
            let element = entry;
            let depth = 0;
            let height = 0; let width = 0;
            let scaleWidth = 1; let scaleHeight = 1;
            do{
                const computedStyle = getComputedStyle(element);
                // console.log(' '.repeat(depth), computedStyle.height, computedStyle.width);
                if(!computedStyle.height.endsWith('%') && !isNaN(parseFloat(computedStyle.height)) && height == 0) height =  parseFloat(computedStyle.height);
                if(!computedStyle.width.endsWith('%') && !isNaN(parseFloat(computedStyle.width)) && width == 0) width =  parseFloat(computedStyle.width);
                const transformValue = computedStyle.transform;
                if(transformValue != 'none') {
                    const transformResult = parseTransform(transformValue);
                    // console.log(transformResult);
                    scaleWidth *= transformResult[0];
                    scaleHeight *= transformResult[3];
                }
                element = element.parentNode; 
                depth++;
            } while(element && element != document && depth < maxDepth);

            return {
                height : (1 / scaleHeight) * height,
                width : (1 / scaleWidth) * width,
            }
        }

        function ContainTransform(entry){
            let element = entry;
            do{
                if(element.style?.transform) return true;
                element = element.parentNode; 
            } while(element)
            return false;
        }

        function reloadCanvas(){
            myChart.value.type = props.type;
            myChart.value.data = props.data;
            myChart.value.options = GetConfig().options;
            if(myChart.value) myChart.value.update();
            resizeCanvas();
            setTimeout(() => {
                myChart.value.resize();
            }, 0);
        }

        return {
            chart: myChart,
            canvasChartEx,
            reloadCanvas,
        }
    },
}
</script>