import vChart from "@/components/atoms/v-chart.vue";
import vButton from "@/components/atoms/v-button.vue";
import vIcon from "@/components/atoms/v-icon.vue";
import vPromise from "@/components/atoms/v-promise.vue";
import vOverlay from "@/components/atoms/v-overlay.vue";
import vPopupContext from "@/components/atoms/v-popup-context.vue";
import vPopupMenu from "@/components/atoms/v-popup-menu.vue";
import vInput from "@/components/atoms/v-input.vue";
import vLangInput from "@/components/atoms/v-lang-input.vue";
import vOpen from "@/components/atoms/v-open.vue";
import vUploader from "@/components/atoms/v-uploader.vue";
import vToggle from "@/components/atoms/v-toggle.vue";
import vCheckbox from "@/components/atoms/v-checkbox.vue";
import vDropdown from "@/components/atoms/v-dropdown.vue";
import vRadioCard from "@/components/atoms/v-radio-card.vue";
import vDisplayDate from "@/components/atoms/v-display-date.vue";

import vModal from "@/components/molecules/modals/v-modal.vue";

import vProfile from "@/components/molecules/v-profile.vue";

export function loadAllGlobalComponents(app) {
    app.component("vChart", vChart);
    app.component("vButton", vButton);
    app.component("vIcon", vIcon);
    app.component("vPromise", vPromise);
    app.component("vOverlay", vOverlay);
    app.component("vPopupContext", vPopupContext);
    app.component("vPopupMenu", vPopupMenu);
    app.component("vInput", vInput);
    app.component("vLangInput", vLangInput);
    app.component("vOpen", vOpen);
    app.component("vUploader", vUploader);
    app.component("vToggle", vToggle);
    app.component("vCheckbox", vCheckbox);
    app.component("vDropdown", vDropdown);
    app.component("vRadioCard", vRadioCard);
    app.component("vDisplayDate", vDisplayDate);

    app.component("vModal", vModal);

    app.component("vProfile", vProfile);
}