<template>
  <component :is="button ? 'button' : (!text ? 'div' : 'p')" @click="goTo" class="rounded-md flex items-stretch filter transition cursor-pointer" style="min-height: 1.5rem" :class="{
  'brightness-90 opacity-70 cursor-default pointer-events-none' : disabled,
  'brightness-100' : !disabled
  }" :disabled="disabled">
    <div
      class="flex-1 items-center grid grid-cols-1 grid-rows-1 group hover:brightness-105 active:brightness-95 transition-all"
      :class="{
        'flex gap-3 px-4 py-1 hover:bg-gray-200 bg-white cursor-pointer transition-all rounded-full border' : small,
        'px-6' : !iconOnly && !text && !minimalTextBorder && !small,
        'bg-gradient-to-tr from-gray-300 to-gray-200 text-black': gray,
        'bg-gradient-to-tr from-purple-300 to-purple-200 text-black': purple && !disabled,
        'bg-gradient-to-tr from-gray-300 to-purple-200 text-black opacity-50': purple && disabled,
        'bg-gradient-to-tr from-gpblue to-blue-400 text-white border border-blue-400': blue,
        'transition bg-transparent border-2 border-gpblue text-gpblue hover:bg-gpblue hover:text-white':
          blueBorder,
        'bg-transparent text-gpblue hover:underline': text,
        'transition bg-transparent border-2 border-gpgray hover:bg-gpgray text-gray-500 hover:text-white':
          textBorder,
        'text-xs border rounded-lg px-2 transition-all hover:bg-slate-200': minimalTextBorder,
        'bg-gradient-to-tr from-orange-400 to-orange-500 text-white': orange,
        'transition bg-transparent border-2 border-gporange text-gporange hover:bg-gporange hover:text-white':
          orangeBorder,
        'bg-gpred text-white': red,
        'transition bg-transparent border-2 border-gpred text-gpred hover:bg-gpred hover:text-white':
          redBorder,
        'transition bg-transparent border-2 border-white text-white hover:bg-gpgray-lightest hover:text-gpgray-dark':
          whiteBorder,
        'rounded-full': rounded,
        'rounded-lg': !rounded && !small,
        'rounded-none' : flat,
        ' px-3 py-1': !text && !minimalTextBorder && !small,
        [customClass]: true,
      }"
    >
      <div class="flex justify-center items-center text-center select-none gap-3" :class="{
        'py-1' : !small,
        'text-sm' : small,
      }">
        <v-icon v-show="leftIcon && icon" :path="icon" :color="iconColor()" :colorHover="iconColorHover()" ></v-icon>
        <slot></slot>
        <v-icon v-show="!leftIcon && icon" :path="icon" :color="iconColor()" :colorHover="iconColorHover()" ></v-icon>
      </div>
    </div>
  </component>
</template>

<script>
import { moveUrl } from "@/router";

export default {
  components: {
  },
  props: {
    small: { type: Boolean, required: false },
    gray: { type: Boolean, required: false },
    blue: { type: Boolean, required: false },
    blueBorder: { type: Boolean, required: false },
    text: { type: Boolean, required: false },
    textBorder: { type: Boolean, required: false },
    minimalTextBorder: { type: Boolean, required: false },
    orange: { type: Boolean, required: false },
    orangeBorder: { type: Boolean, required: false },
    red: { type: Boolean, required: false },
    redBorder: { type: Boolean, required: false },
    whiteBorder: { type: Boolean, required: false },

    purple: { type: Boolean, required : false },

    iconOnly: {type: Boolean, default: false},
    icon: { type: String, default: "" },
    leftIcon: { type: Boolean, default: false },
    rounded: { type: Boolean, required: false },
    flat: { type: Boolean, required: false },

    disabled: { type: Boolean, default: false },
    button: { type: Boolean, default: false },

    customClass: { type: String, default: '' },

    to: {
      type: String,
      default: "",
      required: false,
    },
  },
  setup(props) {
    function iconColor() {
      if (props.blue || props.orange || props.red) return "white";
      else if (props.blueBorder) return "gpblue";
      else if (props.text) return "gpblue";
      else if (props.textBorder) return "gpgray";
      else if (props.minimalTextBorder) return "slate-400";
      else if (props.orangeBorder) return "gporange";
      else if (props.redBorder) return "gpred";
      else if (props.whiteBorder) return "white";
      return "black";
    }

    function iconColorHover() {
      if (props.blue || props.orange || props.red || props.text || props.gray) return "";
      return "white";
    }

    function goTo() {
      console.log(props.to);
      if (props.to) moveUrl(props.to);
    }

    return {
      goTo,
      iconColor,
      iconColorHover,
    };
  },
};
</script>

<style>
</style>