<template>
    <div ref="TARGET" class="stack">
        <slot></slot> 
    </div>
    <teleport to="#POPUP_AREA">
        <transition name="fade">
            <div ref="POPUP" v-show="visible" class="bg-white shadow-md rounded-md fixed top-0 left-0" :class="{
                'pointer-events-auto' : visible,
            }" :style="`transform: translate(${pos.x}px,${pos.y}px)`">
                <slot name="popup"></slot>
            </div>
        </transition>
    </teleport>
</template>

<script lang="ts">

import { ref, watch, onMounted, onUnmounted } from "vue"

export default {
    emits: [
        "open", "close"
    ],
    props:{
        visible: {
            type: Boolean,
            default: true,
        },
        margin: {
            type: Number,
            default: 8,
        },
        position: {
            type: String,
            default: "left", //right, left, top, bottom
        },
        justify: {
            type: String,
            default: "center", //center, start, end
        }
    },
    setup(props, ctx) {
        const TARGET = ref();
        const POPUP = ref();

        const pos = ref({
            x: 0,
            y: 0,
        })

        watch(() => [props.visible, props.position, props.justify], () => {
            setTimeout(ComputePosition, 0);
        });

        onMounted(() => {
            window.addEventListener("resize", ComputePosition);
            window.addEventListener("wheel", ComputePosition);
            window.addEventListener("mousemove", ComputePosition);
            ComputePosition();
        })

        onUnmounted(() => {
            window.removeEventListener("resize", ComputePosition);
            window.removeEventListener("wheel", ComputePosition);
            window.removeEventListener("mousemove", ComputePosition);
        })

        function ComputePosition(){
            if(!POPUP.value || !TARGET.value || !props.visible) return;
            const boudingBoxPopup = POPUP.value?.getBoundingClientRect();
            const boudingBoxTarget = TARGET.value?.getBoundingClientRect();
            
            //1. position
            pos.value.x = boudingBoxTarget.x; // + boudingBoxTarget.width / 2 - boudingBoxPopup.width / 2;
            pos.value.y = boudingBoxTarget.y; // + boudingBoxTarget.height + props.margin;
            if(props.position == "left" || props.position == "right"){
                pos.value.y += boudingBoxTarget.height / 2 - boudingBoxPopup.height / 2;
                if(props.justify == "end") pos.value.y = boudingBoxTarget.y - boudingBoxPopup.height + boudingBoxTarget.height;
                else if(props.justify == "start") pos.value.y = boudingBoxTarget.y;
            }
            if(props.position == "top" || props.position == "bottom"){
                pos.value.x += boudingBoxTarget.width / 2 - boudingBoxPopup.width / 2;
                if(props.justify == "end") pos.value.x = boudingBoxTarget.x - boudingBoxPopup.width + boudingBoxTarget.width;
                else if(props.justify == "start") pos.value.x = boudingBoxTarget.x;
            }
            if(props.position == "left") pos.value.x -= boudingBoxPopup.width + props.margin;
            if(props.position == "right") pos.value.x += boudingBoxTarget.width + props.margin;
            if(props.position == "top") pos.value.y -= boudingBoxPopup.height + props.margin;
            if(props.position == "bottom") pos.value.y += boudingBoxTarget.height + props.margin;
        }

        return {
            TARGET,
            POPUP,

            pos
        }
    },
}
</script>