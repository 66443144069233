<template>
    <div class="flex items-center justify-center">
        <img v-if="noFilter" :src="absolutePath ? absolutePath : `/assets/icons/${path}.svg`" class="iconElement" :class="`w-${size} h-${size}`">
        <div v-if="!noFilter" class="iconElement" :class="`w-${size} h-${size} bg-${color} dark:bg-${darkColor ?? color} group-hover:bg-${colorHover} hover:bg-${colorHover}`" :style="`--icon-local:url('${ absolutePath ? absolutePath : '/assets/icons/'+path+'.svg' }');${customStyle}`"></div>
    </div>
</template>

<script>
export default {
    props: {
        size:{
            type: [String, Number],
            default: 4,
        },
        path: {
            type: String,
            default: 'fi-rr-interrogation',
        },
        absolutePath: {
            type: String,
            default: undefined,
        },
        color: {
            type: String,
            default: "gray-800",
        },
        darkColor: {
            type: String,
            default: undefined,
        },
        colorHover : {
            type: String,
            default: "",
        },
        noFilter : {
            type: Boolean,
            default : false,
        },
        customStyle: {
            type: String,
            default : "",
        }
    },
    setup() {
        
    },
}
</script>

<style>
.iconElement{
    mask-size: cover;
    mask-image: var(--icon-local);
}
</style>