<template>
    <div class="flex gap-2 cursor-pointer filter transition-all" @click="changeValue(modelValue == -1 ? true : !modelValue)" :class="{
        'saturate-0 brightness-75 pointer-events-none' : disabled
    }">
        <div
        class="flex justify-center p-0.5 items-center rounded-md w-5 h-5 border border-gp-gray select-none"
        :class="{
            'bg-gradient-to-tr bg-gpblue from-gpblue to-blue-400 border-transparent': checked(),
            'bg-white border-gpgray' : uncheck(),
            'bg-gpblue bg-opacity-70 border-gpblue-light' : between(),
        }">
            <img v-show="checked()" src="/assets/icons/fi-rr-check.svg" alt="check" class="w-4 filter invert">
            <img v-show="between()" src="/assets/icons/fi-rr-minus-small.svg" alt="check" class="w-4 filter invert">
        </div>
        <p v-show="label" class="font-bold text-sm text-left select-none">{{label}}</p>
        <p v-if="$slots.default" class="text-sm text-left select-none">
            <slot></slot>
        </p>
    </div>
</template>

<script>

export default {
    props: {
        modelValue: [Number, Boolean],
        label: {type: String, default: ''},
        disabled: {
            type: Boolean,
            default : false,
        },
        default: false,
    },
    emits: ['update:modelValue', 'change'],
    setup(props, { emit }) {
        function changeValue(valueChange){
            emit('update:modelValue', valueChange);
            emit("change", valueChange);
        }

        function checked(){
            return (typeof props.modelValue == 'boolean' && props.modelValue) || props.modelValue === 1;
        }

        function uncheck(){
            return (typeof props.modelValue == 'boolean' && !props.modelValue) || props.modelValue === 0;
        }

        function between(){
            return props.modelValue === -1;
        }

        return {
            changeValue,
            checked,
            uncheck,
            between,
        }
    },
}
</script>