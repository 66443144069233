import { createApp,  } from 'vue'
import App from './App.vue'
import router from "./router"

import { API } from "./api/api";

import "./styles/master.css"

import { loadTranslationSystem, initialize as initializeTranslation } from "./translation.js"
import { loadAllGlobalComponents } from "./componentLoader.js";
import { loadUtilitiesSystem, GenerateGUID, urlBase64ToUint8Array } from "./utilities"

import { io } from "socket.io-client"

window.addEventListener("load", () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/service-worker.js").then((registration) => {
      return registration.pushManager.getSubscription().then(async (subscription) => {
        //Subscription already exist
        if(subscription) return subscription;
        //Create a subscription
        const vapidPublicKey = await API.call("webpush.GetVAPIDPublicKey");
        const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
        return registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertedVapidKey
        });
      })
    }).then(async (subscription) => {
      // Subscription part
      // subscription
      console.log(subscription);
      await API.call("webpush.RegisterSubscription", subscription as any);
      (window as any).NOTIF = () => {
        API.call("webpush.DEBUG_NOTIFICATION", subscription as any);
      }
      // setInterval(() => {
        // API.call("webpush.DEBUG_NOTIFICATION", subscription as any, "POUET", 0, 0);
      // }, 1000)
    }).catch(async (e) => {
      await API.call("webpush.RegisterSubscription", undefined as any);
    })
  }
});

// import { authMixin } from './api/auth';
// if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) { document.body.classList.add("dark"); }

(window as any).ToggleDark = function(){ document.body.classList.toggle("dark"); }

async function LoadApp() {
  const app = createApp(App);
  
  API.initialize();

  app.use(router);
  loadAllGlobalComponents(app);
  loadTranslationSystem(app);
  loadUtilitiesSystem(app);

  await initializeTranslation();

  app.mount('#app');
}

(window as any).DEV_MODE = import.meta.env.MODE;

LoadApp();