<template>
  <section class="flex flex-col w-full h-full overflow-y-auto dark:bg-slate-900 dark:text-white">
    <!-- END POPUP SECTION -->
    <router-view></router-view>
  </section>
</template>

<script lang="ts" setup>
import { SetDarkMode } from './utilities';

if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  // SetDarkMode(true);
}
</script>