<template>
  <div class="group flex flex-col flex-1 p-3 pt-4 justify-between rounded-xl bg-gradient-to-tr from-gray-50 to-white border border-gp-gray overflow-hidden gap-4 cursor-pointer transition-all duration-300 transform-gpu bg-white" :class="[
      cardValue != modelValue ? `border-gpgray-light hover:border-${hoverColor} hover:-translate-y-0.5 hover:shadow-md shadow-gray-400` : '',
      cardValue == modelValue ? `border-${selectedColor} -translate-y-0.5 shadow shadow-gray-300` : '',
      disabled ? 'pointer-events-none brightness-75 bg-gray-100' : ''
    ]"
    @click="selectCurrent"
  >
    <div class="flex flex-col justify-center flex-1">
      <slot></slot>
      <v-icon v-show="icon" :path="icon" :size="iconSize" class="transition-all duration-500" :noFilter="noFilter"></v-icon>
    </div>
    <h2 v-show="label" class="text-base leading-4 font-semibold text-center">{{ label }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    noFilter: {
      type: Boolean,
      default: false,
    },
    iconSize:{
      type: Number,
      default : 16,
    },
    icon: {
      type: String,
      default: "",
    },
    hoverColor: { type: String, default: "gpblue-light" },
    selectedColor: { type: String, default: "gpblue" },
    cardValue: { type: [String, Number, Object, Boolean], default: undefined },
    modelValue: {
      type: [String, Array, Object, Number, Boolean],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  emits: ["update:modelValue", "change"],
  setup(props, { emit }) {

    // Select current card by setting the modelValue as the cardValue
    function selectCurrent() {
      emit("update:modelValue", props.cardValue);
      emit("change", props.cardValue);
    }

    // Return true if the current card is selected
    function isSelected() {
      return props.modelValue === props.cardValue;
    }

    return { isSelected, selectCurrent, };
  },
};
</script>