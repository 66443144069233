<template>
    <div ref="dropdown" class="stack dark:text-gray-400 transition-all" :class="{
        'saturate-0 brightness-90 opacity-50 pointer-events-none' : disabled,
    }">
        <div class="stack" @mousedown="ToggleDisplay()">
            <slot name="header" :displayOptions="displayOptions" :displayTextElement="displayTextElement" :ToggleDisplay="ToggleDisplay" :label="label">
                <v-input v-if="editable" v-model="modelValue" :label="label" @change="(e) => $emit('update:modelValue', e)" @input="() => {
                    if(activeFilter) search = modelValue
                }"/>
                <div v-if="!editable" class="self-stretch flex items-center p-2 gap-4 rounded-lg group transition h-11 cursor-pointer"  :class="{
                    'border-gpblue' : displayOptions && !minimal,
                    'bg-white dark:bg-gray-800 dark:border-gray-700 border': !minimal,
                }">
                    <p class="w-full text-left" :class="{ 'displayElements' : !minimal && label }" style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">{{displayTextElement}}</p>
                    <v-icon path="fi-rr-angle-down" color="gray-600" class="p-1"></v-icon>
                </div>
                <div v-if="!editable && label" class="flex self-stretch items-strech pointer-events-none">
                    <span v-show="label" class="col-start-1 row-start-1 transition spanElement text-gray-500 dark:text-gray-500 font-bold spanMove">{{label}}</span>
                </div>
            </slot>
        </div>
        <div
        class="self-stretch col-start-1 row-start-1 h-1 z-50 transition duration-150 select-none"
        :class="{'' : displayOptions,'opacity-0 select-none pointer-events-none' : !displayOptions,}"
        :style="`transform: ${displayOptions ? 'translateY(50px);' : 'translateY(55px);'}`">
            <section class="bg-white dark:bg-gray-800 dark:border-gray-700  shadow-lg border rounded-lg">
                <label v-if="activeFilter && !editable" class="flex cursor-text">
                    <v-icon path="fi-rr-search" color="gray-600" class="px-4"></v-icon>
                    <input ref="searchElement" v-model="search" type="text" :placeholder="t('search_item')" style="min-width: 0px;" class="flex-1 py-2 outline-none bg-transparent">
                    <v-icon path="fi-rr-cross" color="gray-200" class="px-4 cursor-pointer transition" :class="`${search ? '' : 'opacity-0'}`" @click="search = ''"></v-icon>
                </label>
                <hr v-if="activeFilter && !editable" class="dark:border-gray-700">
                <div v-if="displayOptions" class="flex flex-col overflow-y-auto p-1" style="max-height: 25vh">
                    <div class="flex flex-col">
                        <slot name="before" :data="{ search, count: countProposal() }"></slot>
                    </div>
                    <div class="flex flex-col gap-1">
                        <div v-for="(i, index) in choices"
                            :key="i" v-show="!search || (typeof i == 'object' ? (i.label ?? index) : i).toLowerCase().includes(search.toLowerCase())"
                            class="p-2 px-3 cursor-pointer select-none transition rounded-md"
                            :class="`${selected.includes(Array.isArray(choices) ? index : i) ? 'bg-gradient-to-tr from-gpblue to-blue-400 text-white' : ' hover:bg-gray-200 dark:hover:bg-gray-700'}`"
                            @click="selectElement(Array.isArray(choices) ? index : i)">
                            <v-checkbox v-if="multiple" :modelValue="selected.includes(index)" @click.stop="selectElement(Array.isArray(choices) ? index : i)">{{typeof i == 'object' ? i.label : i}}</v-checkbox>
                            <p v-if="!multiple && !$slots.default">{{typeof i == 'object' ? i.label : Array.isArray(choices) ? i : t(index) }}</p>
                            <slot :data="{ search, count: countProposal(), text: (typeof i == 'object' ? i.label : Array.isArray(choices) ? i : t(index)), content: i, index }"></slot>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <slot name="after" :data="{ search, count: countProposal() }"></slot>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <!-- <p>{{selected}}</p> -->
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from "vue";

import { t } from "@/translation"

export default {
    props: {
        label: {
            type: String,
            default : false
        },
        editable: {
            type: Boolean,
            default: false,
        },
        choices: {
            /*
                ['A', 'B', 'C']
                or
                [{ label : 'A', value: 0 }, { label : 'B', value: 1 }, { label : 'C', value: 2 }]
                or
                { "A" : 0, "B" : 1, "C": 2 }
            */
            type: Object,
            default: [],
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        requireOne: {
            type: Boolean,
            default: false,
        },
        activeFilter: {
            type: Boolean,
            default: false,
        },
        minimal: { type: Boolean, default: false },
        modelValue: [Number, String, Array, Object],
        disabled : { type: Boolean, default: false },
        default: false,
    },
    emits: ['update:modelValue', 'change'],
    setup(props, {emit}) {
        const dropdown = ref();
        const searchElement = ref();
        const displayOptions = ref(false);
        const search = ref("");

        const selected = ref([]);
        const displayTextElement = ref("");

        watch(displayOptions, (first, second) => {
            if(!first) search.value = "";
            else{
                if(searchElement.value){
                    // searchElement.value.focus();
                }
            }
        });

        watch(() => props.modelValue, (first, second) => {
            updateSelectedBasedOnValue(first);
            displayText();
        });

        watch(() => props.choices, (first, second) => {
            updateSelectedBasedOnValue(props.modelValue);
            displayText();
        });

        /**
         * Generate automatic selected value based on current value (from v-model for example)
         */
        function updateSelectedBasedOnValue(value){
            // console.log(value);
            selected.value = [];
            if(!props.choices || props.choices.length == 0) return;
            if(Array.isArray(props.choices)){
                let choicesMapped = [...props.choices];
                if(typeof choicesMapped[0] == 'object'){
                    choicesMapped = choicesMapped.map((e) => {
                        return e.value;
                    })
                }
                
                for(let i = 0; i < choicesMapped?.length; i++){
                    let ok= JSON.stringify(choicesMapped[i]) == JSON.stringify(value);
                    if(!ok && typeof value == 'object'){
                        for(let j = 0; j < value?.length; j++){
                            if(JSON.stringify(choicesMapped[i]) == JSON.stringify(value[j]) ){
                                ok = true;
                                break;
                            }
        
                        }
                    }
                    
                    if(ok){
                        selected.value.push(i);
                        if(!props.multiple) break;
                    }
                }
            }else{
                // let index = 0;
                for(let i in props.choices){
                    if(props.choices[i] == value) selected.value.push(props.choices[i]);
                    // index += 1
                }
            }
            displayText();
        }

        function countProposal(){
            let total = 0;
            const lowerSearch = search.value.toLowerCase();

            for(let i in props.choices){
                const label = ((typeof props.choices[i] == 'object' ? (props.choices[i].label ?? i) : i) + "").toLowerCase();
                if(!search || label.includes(lowerSearch)) total++;
            }

            return total;
        }

        function onClickEvent(e){
            if(!dropdown.value.contains(e.target)){
                displayOptions.value = false;
            }
        }

        onMounted(() => {
            document.body.addEventListener("mousedown", onClickEvent);
            updateSelectedBasedOnValue(props.modelValue);
        })

        onUnmounted(() => {
            document.body.removeEventListener("mousedown", onClickEvent);
        })

        function displayText(){
            let toDisplay = "";
            if(Array.isArray(props.choices)){
                for(let i = 0; i < selected.value.length; i++){
                    const element = props.choices[selected.value[i]];
                    if(typeof element == 'object') toDisplay += element.label;
                    else toDisplay += t(element);
                    toDisplay += (i < selected.value.length -1 ? ', ' : '');
                }
            }else{
                const indexProps = Object.keys(props.choices);
                for(let i = 0; i < selected.value.length; i++){
                    const element = indexProps[selected.value[i]];
                    if(typeof element == 'object') toDisplay += element.label;
                    else toDisplay += t(element);
                    toDisplay += (i < selected.value.length -1 ? ', ' : '');
                }
            }
            // if(toDisplay == "" && props.multiple && props.requireOne) toDisplay = "(╯°□°）╯︵ ┻━┻";
            displayTextElement.value = toDisplay;
        }

        function selectElement(index){
            // console.log("SELECT ELEMENT", index);
            if(props.multiple){
                const indexOf = selected.value.indexOf(index);
                if(indexOf >= 0 && (!props.requireOne || (props.requireOne && selected.value.length > 1))){
                    selected.value.splice(indexOf, 1);
                }else{
                    selected.value.push(index);
                }
                let valueFinal = [];
                for(let i = 0; i < selected.value.length; i++){
                    const element = props.choices[selected.value[i]];
                    valueFinal.push(typeof element == 'object' ? element.value : element);
                }
                emit('update:modelValue', valueFinal);
                emit("change", selected.value);
            }else{
                selected.value = [index];
                if(Array.isArray(props.choices)){
                    emit('update:modelValue', typeof props.choices[index] == 'object' ? props.choices[index].value : props.choices[index]);
                }else{
                    const indexProps = Object.keys(props.choices);
                    emit('update:modelValue', props.choices[indexProps[index]]);
                }
                displayOptions.value = false;
                emit("change", selected.value);
            }
            displayText();
        }

        function ToggleDisplay(){
            displayOptions.value = !displayOptions.value;
        }

        return {
            dropdown,
            displayOptions,
            selected,
            search,
            searchElement,

            displayText,
            displayTextElement,
            selectElement,

            countProposal,

            ToggleDisplay,
        }
    },
}
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b9b9b9;
  border-radius: 100vh;
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b9b9b9;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}
.spanMove{
    transform-origin: left center;
    transform: scale(0.6) translate(1rem, -0.3rem);
}

.displayElements{
    transform: translateY(20%);
}
</style>