<template>
    <div @click.prevent="changeValue(!modelValue)" class="flex flex-col justify-start items-stretch gap-2 cursor-pointer">
        <p v-show="label" class="font-bold text-sm text-left select-none">{{label}}</p>
        <div
        class="flex justify-start p-0.5 items-center rounded-full w-10 h-6 border transition duration-150 ease-in-out select-none"
        :class="{
            'bg-gpblue bg-gradient-to-tr from-gpblue to-blue-400 border-transparent': modelValue,
            'border-gray-300 bg-gray-200 dark:bg-gray-800 dark:border-gray-700' : !modelValue,
        }">
            <div
            class="h-full w-6/12 rounded-full transition duration-150 ease-in-out shadow-sm"
            :class="{
                'bg-gpgray-lightest': modelValue,
                'bg-white dark:bg-gray-500 shadow' : !modelValue,
            }"
            :style="`${modelValue ? 'transform:translateX(calc(100% + 0rem));' : ''}`"></div>
        </div>
        <input type="checkbox" ref="checkbox" :value="modelValue" class="hidden"/>
    </div>
</template>

<script>

import {ref } from "vue"

export default {
    props: {
        label: {
            type: String,
            default : "",
        },
        modelValue: {
            type: Boolean,
            default : false,
        }
    },
    emits: ['update:modelValue', 'change'],
    setup(props, { emit }) {
        function changeValue(valueChange){
            emit('update:modelValue', valueChange);
            emit('change', valueChange);
        }

        const checkbox = ref();

        return {
            checkbox,
            changeValue,
        }
    },
}
</script>